exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-carrito-js": () => import("./../../../src/pages/carrito.js" /* webpackChunkName: "component---src-pages-carrito-js" */),
  "component---src-pages-contacto-js": () => import("./../../../src/pages/contacto.js" /* webpackChunkName: "component---src-pages-contacto-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-nosotros-js": () => import("./../../../src/pages/nosotros.js" /* webpackChunkName: "component---src-pages-nosotros-js" */),
  "component---src-pages-preguntas-frecuentes-js": () => import("./../../../src/pages/preguntas-frecuentes.js" /* webpackChunkName: "component---src-pages-preguntas-frecuentes-js" */),
  "component---src-pages-productos-index-js": () => import("./../../../src/pages/productos/index.js" /* webpackChunkName: "component---src-pages-productos-index-js" */),
  "component---src-pages-productos-strapi-categoria-descripcion-tsx": () => import("./../../../src/pages/productos/{StrapiCategoria.descripcion}.tsx" /* webpackChunkName: "component---src-pages-productos-strapi-categoria-descripcion-tsx" */),
  "component---src-pages-productos-strapi-producto-nombre-tsx": () => import("./../../../src/pages/productos/{StrapiProducto.nombre}.tsx" /* webpackChunkName: "component---src-pages-productos-strapi-producto-nombre-tsx" */),
  "component---src-pages-using-ssr-tsx": () => import("./../../../src/pages/using-ssr.tsx" /* webpackChunkName: "component---src-pages-using-ssr-tsx" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

