/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/
 */

// You can delete this file if you're not using it

import "./src/assets/themefisher-font/style.css"
import "bootstrap/dist/css/bootstrap.min.css"
import "./src/assets/animate/animate.css"

const $ = require('jquery');
window.$ = $;
window.jQuery = $;

require('bootstrap/dist/js/bootstrap.min.js');
require("./src/assets/slick/slick.min.js");
require("./src/assets/slick/slick-animation.min.js");